import { ENV_API_URL } from "config/config";

// const BASE_URL = `${ENV_SIMULATION_URL}/pricing`;
const BASE_URL = `${ENV_API_URL}/pricing`;

export const AUTHORIZE_USER_API_URL = `${ENV_API_URL}/v1/authorizeUserDetails`;
export const BRAND_LADDERS_DASHBOARD_DATA_API_URL = `${ENV_API_URL}/dashboard/v1.2/brandladdersDashboardData`;
export const ELASTICITY_CURVES_API_URL = `${ENV_API_URL}/elasticity-curves`;
export const FAVORITE_SCENARIOS_API_URL = `${ENV_API_URL}/scenarios/favourite-scenario`;
export const FEATURE_FLAG_API_URL = `${ENV_API_URL}/featureFlag/features`;
export const FOLDERS_API_URL = `${ENV_API_URL}/folders`;
export const HOMEPAGE_DATA_API_URL = `${ENV_API_URL}/pricing/v1.1/homePageData`;
export const HOMEPAGE_FILTER_DATA_API_URL = `${ENV_API_URL}/pricing/v1.2/homePageFiltersData`;
export const INCENTIVE_CURVES_API_URL = `${ENV_API_URL}/incentive-curves`;
export const LINE_PRICE_GROUPINGS_API_URL = `${ENV_API_URL}/products/line-price-groupings`;
export const LINE_PRICE_GROUPINGS_API_URL_PMF = `${ENV_API_URL}/products/line-price-groupings-pmf`;
export const MAINTENANCE_MODE_API_URL = `${ENV_API_URL}/maintenance`;
export const PRODUCT_ALL_CATEGORIES_API_URL = `${ENV_API_URL}/products/all_categories`;
export const PRODUCT_DETAILS_API_URL = `${BASE_URL}/productDetails`;
export const PRICING_METRICS_API_URL = `${BASE_URL}/pricingSimulationMetrics`;
export const PRODUCT_PRICE_DETAILS_API_URL = `${BASE_URL}/productPriceDetails`;
export const PRODUCTS_API_URL = `${ENV_API_URL}/products`;
export const PRODUCTS_V2_API_URL = `${ENV_API_URL}/products/v2`;
export const OUTPUT_DETAILS_API_URL = `${ENV_API_URL}/output-details`;
export const OUTPUT_DETAILS_API_URL_V2 = `${ENV_API_URL}/output-details/v2`;
export const REFERENCE_SCENARIO_VALUES_API_URL = `${ENV_API_URL}/scenarios/reference-scenario-values`;
export const REFERENCE_SCENARIOS_API_URL = `${ENV_API_URL}/reference-scenarios`;
export const SCENARIOS_API_URL = `${ENV_API_URL}/scenarios`;
export const SCENARIOS_V2_API_URL = `${ENV_API_URL}/scenarios/v2`;
export const DRAFT_SCENARIOS_API_URL = `${ENV_API_URL}/scenarios/draft`;
export const SCENARIOS_BULK_DELETE_API_URL = `${ENV_API_URL}/scenarios/bulk-delete`;
export const SCENARIOS_FOLDERS_API_URL = `${ENV_API_URL}/scenarios/folders`;
export const VOLUME_DECOMP_API_URL = `${ENV_API_URL}/volume-decomp`;
export const VOLUME_FLOWS_API_URL = `${ENV_API_URL}/volume-flows`;
export const EXCEL_DOWNLOAD_API_URL = `${ENV_API_URL}/excel/download`;
export const MARKET = `${ENV_API_URL}/market`;
export const BPE_VS_DPE = `${ENV_API_URL}/dashboard-charts/dpe-vs-bpe`;
export const XPE_VS_DPE = `${ENV_API_URL}/dashboard-charts/dpe-vs-xpe`;
export const DPE_TREE = `${ENV_API_URL}/dashboard-charts/tree`;
export const ELASTICITES_FILTERS = `${ENV_API_URL}/dashboard/filter-elasticities`;
export const PRICE_ELASTICITIES_DATA = `${ENV_API_URL}/dashboard/elasticities`;
export const CANNIBALIZATION_DATA = `${ENV_API_URL}/dashboard/cannibalization`;
export const PPGINSIGHTS_FILTERS = `${ENV_API_URL}/dashboard/filter-insights`;
export const PPGINSIGHTS_FILTERS_V2 = `${ENV_API_URL}/dashboard/v2/filter-insights`;
export const HISTORICAL_PERFORMANCE_FILTERS = `${ENV_API_URL}/dashboard/filter-historical-performance`;
export const HISTORICAL_PERFORMANCE_FILTERS_V2 = `${ENV_API_URL}/dashboard/v2/filter-historical-performance`;
export const SIMULATIONS_INPUTS_DATA_URL = `${ENV_API_URL}/simulations/simulation-data`;
export const GENERATE_SIMULATIONS_URL = `${ENV_API_URL}/simulations/generate-simulation`;
