// currency symbol
export const CURRENCY_SYMBOL = "$";

export const SCREEN_NAME = {
  multiPack: "Multipack",
  mpack: "Multipack",
  takeHome: "Take Home",
  potato_chips: "Potato Chips",
  tortilla_chips: "Tortilla Chips",
  utc: "Unflavored Tortilla Chips",
  corn_chips: "Corn Chips",
  extruded_pellets: "Extruded Pellets",
  snack_mix: "Snack Mix",
  rte_popcorn: "Popcorn",
  EXTRUDED_PELLETS: "Extruded Pellets",
  FRUIT_VEGGIE_CHIPS: "Fruit & Veggie Chips",
  OTHER_GRAIN_SNACKS: "Other Grain Snacks",
  POTATO_CRISPS: "Potato Crisps",
  TORTILLA_CHIPS: "Tortilla Chips",
};

export const SIZES = {
  xl: "XL",
  xxl: "XXL",
  lg: "LG",
  sp: "SP",
  vp: "VP",
};

// TODO: Check if we can consolidate the mpack subcategory names after flus rework
export const SUBCATEGORY_NAMES = {
  "Multi Packs": "Multipack",
  "Potato Chips": "Potato Chips",
  "Tortilla Chips": "Tortilla Chips",
  Utc: "Unflavored Tortilla Chips",
  corn_chips: "Corn Chips",
  potato_chips: "Potato Chips",
  tortilla_chips: "Tortilla Chips",
  extruded_pellets: "Extruded Pellets",
  snack_mix: "Snack Mix",
  rte_popcorn: "Popcorn",
  Mpack: "Multipack",
  mpack: "Multipack",
};

/* =================================
    Constants for Pricing Simulation
===================================*/

/* Export Simulations */
export const EXPORT_MORE_THAN_10_SIMS =
  "The items will be exported as a ZIP folder as it exceeds 10 simulations. If you wish to export as a consolidated excel, please select up to 10 simulations";
export const EXPORT_SIMULATIONS =
  "Select whether you wish to export the simulations as a zip folder or as a consolidated file.";

/* Delete Simulation */
export const DELETE_FOLDER =
  "Are you sure you want to delete this folder? Once deleted the folder cannot be recovered.";

export const DELETE_SIMULATION =
  "Are you sure you want to delete this simulation? Once deleted the simulation cannot be recovered.";

/* Rename Folder */
export const RENAME_FOLDER_SUCCESS = "Folder name updated successfully";

/* Rename Simulation */
export const RENAME_SIM_SUCCESS = "Simulation name updated successfully";

// User Role
export const USER_ROLE = {
  GENERAL: {
    ID: 1,
    TYPE: "general",
  },
  ADMIN: {
    ID: 2,
    TYPE: "admin",
  },
  MANAGER: {
    ID: 3,
    TYPE: "management",
  },
  PRICING: {
    ID: 4,
    TYPE: "pricingUser",
  },
};

export const USER_TAG = {
  1: "Internal",
  2: "External",
};

export const CMS_PATHS = {
  faq: "/api/views/faqs_api/get",
  knownIssues: "/api/views/known_issues_api/get",
  releaseNotes: "/api/views/release_notes_api/get",
  token: "/oauth/token",
} as const;

export const PRICING_APP_ID = 2;
export const DEFAULT_MARKET = "FLUS";
export const TOTAL_GROCERIES_MARKET_ID = 1;
